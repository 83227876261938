<template>
  <div id="article">
    <h1>Telecom Academy – The future is in IT!</h1>
    <img
      :src="path"
      style="  float: left;
  margin-right: 2vw; width: 40%;"
    />
    <p>
      Cu toții știm sau aflăm în primii ani de facultate cât de importantă este
      Investiția în propria educație.
    </p>
    <p>
      Parcursul educational începe cu promovarea examenului de Bacalaureat și
      înscrierea la facultatea dorită, moment în care, din punct de vedere
      psihologic, realizăm că începem să ne clădim, cărămidă cu cărămidă, o
      carieră.
    </p>

    <p>
      Din păcate, programele de educație formală din cadrul universităților nu
      sunt întotdeauna actualizate, conform cu cerințele din piața muncii sau nu
      pot să acopere în totalitate multitudinea de noțiuni necesare și râvnite
      pe piața muncii. Să nu uităm totuși că discutăm despre un domeniu extrem
      de dinamic.
    </p>
    <p>
      Telecom Academy este unul dintre cele mai mari centre de training din
      România. În 2007 a organizat primele cursuri Cisco, extrem de populare
      printre studenții la politehnică pentru care cunoștințele de bază de
      rețelistică sunt esențiale, în calitate de Cisco Networking Academy.
    </p>
    <p>
      Paleta de cursuri pe care o organizează cei de la Telecom Academy s-a
      extins exponențial și fără încetare. În permanență, aceștia lucrează la
      dezvoltarea de noi cursuri pentru cele mai în vogă tehnologii și tool-uri,
      promovând educația non-formală. Specialiștii IT români sunt unii dintre
      cei mai apreciați la nivel internațional, datorită cunoștințelor pe care
      le dețin și a dedicării din procesul de studiu.
    </p>
    <p>
      Dacă vrei să afli detalii cu privire la cursurile organizate de Telecom
      Academy, accesează
      <a href="http://www.telacad.ro/">www.telacad.ro</a> deoarece acolo găsești
      detalii complete cu privire la programa, diplome sau certificări,
      instructori sau prețuri.
    </p>
    <p>
      Cursurile organizate de partenerii noștri sunt împărțite pe categorii:
    </p>
    <ul>
      <li>
        • Programare în diverse limbaje de programare: Java, Python, JavaScript,
        PHP, Baze de Date, C++, Machine Learning, Kotlin, Golang
      </li>
      <li>• Rețelistică: CCNA, CCNP</li>
      <li>
        • Sisteme de operare: Linux – System Administrator & Server
        Administrator, Windows Server, Virtualization Fundamentals
      </li>
      <li>
        • DevOps: Docker, Kubernetes, Jenkins, Continuous Integration, GIT &
        Bitbucket
      </li>
      <li>
        • DevOps: Docker, Kubernetes, Jenkins, Continuous Integration, GIT &
        Bitbucket
      </li>
      <li>
        • Microsoft Office
      </li>
      <li>
        • Limbi Străine
      </li>
    </ul>
    <p>
      Cursurile sunt gândite pe niveluri de dificultate, fie că vrei să te
      inițiezi în domeniul IT&C, fie că ești student și vrei să începi studiul
      unei tehnologii sau tool despre care ai auzit foarte multe de la colegi
      mai mari, fie că vrei să iți faci o reconversie profesională.
    </p>
    <img :src="path2" />
    <p>
      Prin intermediul Telecom Academy te poți înscrie la o serie de 7 cursuri
      <a
        href="https://www.telacad.ro/toate-cursurile/cursuri-online-gratuite-cu-self-enrollment/"
        >gratuite</a
      >
      dezvoltate de către Cisco Networking Academy, cursuri esențiale pentru
      oricine dorește o carieră în IT. Înscrierile se fac printr-un simplu email
      pe <a href="mailto:office@telacad.ro">office@telacad.ro</a>.
    </p>
    <h2>Care sunt cele mai populare cursuri în 2020?</h2>
    <p>
      Cele mai râvnite cursuri sunt de fapt tehnologiile cele mai râvnite de
      angajatori, iar în 2020 acestea sunt: cursurile CCNA 1-3, Java și Python.
      Java a fost întotdeauna în top, este unul din limbajele de programare cele
      mai populare din ultimii 10 ani, însă Python avansează enorm de la an la
      an și este noul “must have” pentru Software Engineers, Software
      Developers, Network Engineers, DevOps Engineers, Data Scientists și nu
      numai.
    </p>
    <h2>Ce este educația non-formala?</h2>
    <p>
      Telecom Academy promovează educația non-formala din spatele unei echipe
      tinere, a unei platforme de e-learning moderne
      <a href="https://cursuri.telacad.ro">(https://cursuri.telacad.ro)</a> pe
      care creează intens de la zero în permanență, cursuri noi. Accentul cade
      pe materialele video preînregistrate de către instructor care dărâmă
      bariera distanței. În era tehnologiei și a digitalizării, poți învăța de
      oriunde, ai nevoie de un device conectat la Internet, chef și spor.
    </p>
    <p>
      Materialul de studiu pentru un curs este organizat pe capitole care conțin
      la final examinări gândite pentru a iți consolida cunoștințele, un
      gradebook ce poate fi accesat în permanență, resurse și exerciții
      practice, laboratoare filmate, posibilitatea de a iți descărca diploma
      direct din contul de cursant sau de a o share-ui direct către angajator.
    </p>
    <p>
      Educația nu trebuie să cunoască limite, asa că ai de ales între diverse
      modalități de a urma cursurile:
    </p>
    <ul>
      <li>
        • La sediul Telecom Academy din București, cu predare face to face
      </li>
      <li>
        • De oriunde, cu predare cu instructor live, prin intermediul claselor
        virtuale și veți folosi Cisco Webex, un tool extrem de intuitiv
      </li>
      <li>
        • Online, în regim self-study, dacă dorești să îți stabilești singur
        ritmul de studiu, în funcție de propriul program
      </li>
    </ul>
    <img :src="path3" />
    <p>
      Aruncă o privire pe
      <a href="https://www.telacad.ro/blog-telecom-academy/">blogul</a> Telecom
      Academy pentru a afla noutăți, promoții sau sfaturi pentru orientarea în
      cariera sau alegerea cursurilor potrivite pentru tine. Le poți scrie celor
      de la Telecom Academy pe
      <a href="mailto:office@telacad.ro">office@telacad.ro</a> sau 0727761355
      pentru a solicita consultanță gratuită sau poți chiar să le faci o
      <a href="https://www.telacad.ro/contact/">vizită</a>.
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: require("@/assets/images/Articles/IlustratieTelAcad.png"),
      path2: require("@/assets/images/Articles/whatever.png"),
      path3: require("@/assets/images/Articles/whatever2.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
#article {
  position: relative;
  top: 10vh;
  width: 100vw;
  margin: auto;
  //   background-color: #1D0E0E;
  //   box-shadow: 0.1vw 0.1vw 1vw rgba(16.1%, 19.6%, 32.9%, 0.5);
  padding: 2vh 10vw 2vh 10vw;
  border-radius: 1vw;
  margin-bottom: 20vh;
}
p,
li {
  display: block;
  font-family: $primary-font;
  margin-bottom: 2vh;
  text-align: left;
  font-size: 2.3vh;
  color: black;
}

h1,
h2 {
  margin-top: 2vh;
  margin-bottom: 3.5vh;
  color: black;
}

li {
  margin-left: 5%;
}

img {
  margin-bottom: 2vh;
  margin-top: 2vh;
  height: auto;
}

a {
  color: #0645ad;
}

@media (max-width: 900px) {
  #article {
    width: 100vw;
  }
  h1,
  h2 {
    font-size: 3vh;
  }
  p,
  li {
    font-size: 2.2vh;
  }
  img {
    float: none;
    width: 90%;
  }
}
</style>
